/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import AdoptEmbryoButton from './AdoptEmbryoButton';
import DonateEmbryoButton from './DonateEmbryoButton';

const Apply = () => {
  const data = [
    {
      title: 'Apply for Adoption',
      description:
        'We understand your desire to complete your family, and embryo adoption allows you an adoption alternative to experience pregnancy and childbirth.',
      ctaLink: 'https://members.embryodonation.org/',
    },
    {
      title: 'Apply for Donation',
      description:
        'This is your chance to give life and hope to another family! Thank you for valuing these precious lives. Begin filling out the NEDC application to donate embryos.',
      ctaLink: 'https://members.embryodonation.org/',
    },
  ];
  return (
    <section
      sx={{
        background: '#EFF9F8',
      }}
    >
      <div
        className="content"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          padding: '4rem',
          gap: '32px',
        }}
      >
        {data.map((item) => {
          return (
            <div
              key={item.title}
              sx={{
                flex: ['1 0 100%', null, '1 0 calc(50% - 1rem)'],
                maxWidth: ['100%', null, 'calc(50% - 1rem)'],
                background: ' #FFFFFF',
                boxShadow: '0px 4px 8px rgba(186, 228, 224, 0.5)',
                padding: ['2rem', null, '4rem'],
                textAlign: 'center',
              }}
            >
              <h3
                sx={{
                  variant: 'text.xl',
                  textAlign: 'center',
                  color: '#666666',
                }}
              >
                {item.title}
              </h3>
              <p
                sx={{
                  fontSize: ['1rem', null, '1.25rem'],
                  textAlign: 'center',
                  color: '#666666',
                  my: '1.5rem',
                }}
              >
                {item.description}
              </p>
              {item.title !== 'Apply for Adoption' ? 
              (
                <DonateEmbryoButton
                  style={{
                    display: 'inline-block',
                    bg: '#8BBFBD',
                    color: '#FFFFFF',
                    fontSize: ['1rem', null, '1rem'],
                    border: '1px solid #8BBFBD',
                    borderRadius: '5px',
                    px: '3rem',
                    py: '1rem',
                    '&:hover': {
                      bg: 'transparent',
                      color: '#666666',
                    },
                  }}
                />
              )
              : (
                <AdoptEmbryoButton
                  style={{
                    display: 'inline-block',
                    bg: '#8BBFBD',
                    color: '#FFFFFF',
                    fontSize: ['1rem', null, '1rem'],
                    border: '1px solid #8BBFBD',
                    borderRadius: '5px',
                    px: '3rem',
                    py: '1rem',
                    '&:hover': {
                      bg: 'transparent',
                      color: '#666666',
                    },
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Apply;
