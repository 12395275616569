/** @jsx jsx */
import * as React from 'react';
import Header from '../components/Common/Header';
import Intro from '../components/Donate/Intro';
import Faqs from '../components/Donate/Faqs';
import Layout from '../components/Layout/index';
import Apply from '../components/Common/Apply';
import { Seo } from '../components/Seo';
import Resources from '../components/Common/Resources';
import AskingQuestions from '../components/Donate/AskingQuestions';
import WhyDonate from '../components/Donate/WhyDonate';
import DonateAtoZ from '../components/Donate/DonateAtoZ';
import AfterDonation from '../components/Donate/AfterDonation';
import Legal from '../components/Donate/Legal';
import Anonymity from '../components/Donate/Anonymity';
import SectionHeader from '../components/Common/SectionHeader';
import { jsx } from 'theme-ui';

const DonatePage = () => {
  const data = [
    {
      title: 'Informed Consent to Donate Embryos',
      link: '/Informed_Consent_to_Donate_6.11.24.pdf',
    },
    {
      title: 'Agreement for open Donation/Adoption',
      link: '/ODA_working_copy_6.2024.pdf',
    },
    {
      title: 'Embryo Donation Medical checklist',
      link: '/NEDC_Embryo_Donation_Checklist.pdf',
    },
    {
      title: 'Embryo Donor Family Information',
      link: '/NEDC_Donor_Bio_Information.pdf',
    },
    {
      title: 'Release of Information',
      link: '/NEDC-Release-of-Information-Form.pdf',
    },
  ];
  return (
    <Layout>
      <Seo
        title="Embryo Donation at the NEDC | National Embryo Donation Center"
        description="Consider embryo donation for your remaining embryos left from your IVF cycle and allow another couple to build their family"
      />
      <div>
        <Header
          title="Donation"
          description="The Gift of Life"
          video="https://youtu.be/XkVTdtRB3mc"
          background={'/images/header/omar-lopez-_H1ZmUzwp9w-unsplash.png'}
        />
        <Intro />
        <AskingQuestions />
        <WhyDonate />
        <DonateAtoZ />
        <AfterDonation />
        <section>
          <div className="content">
            <SectionHeader
              heading="Communication of Information with Donor-Conceived Children and Realities of DNA Testing Opportunities"
            />
            <p
              sx={{
                variant: 'text.normal',
              }}
            >
              <b>
                We encourage all Donor Families and Recipient Families to be open and honest with
                their children regarding their birth stories. There are many books and websites
                available to help guide you through this journey.
              </b>
            </p>
          </div>
        </section>
        <Legal />
        <Faqs />

        <Anonymity />
        <Resources
          data={data}
          description={`We know you probably have a lot of questions about the embryo donation process. We’ve
          compiled some helpful resources here for your benefit but, if you have any questions or
          need additional information, please do not hesitate to contact us toll free at
          <b>866-585-8549.</b>`}
          title="Donation Resources"
        />
        <Apply />
      </div>
    </Layout>
  );
};

export default DonatePage;
